export default [
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: () => import('../views/ListView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Проекты',
  //     breadcrumb: [
  //       {
  //         text: 'Проекты',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/projects/create',
    name: 'projects.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание проекта',
      breadcrumb: [
        {
          text: 'Компания',
          active: false,
          to: {
            name: 'company.show',
          },
        },
        {
          text: 'Создание проекта',
          active: true,
        },
      ],
    },
  },
  {
    path: '/projects/edit/:id',
    name: 'projects.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование проекта',
      breadcrumb: [
        {
          text: 'Компания',
          active: false,
          to: {
            name: 'company.show',
          },
        },
        {
          text: 'Редактирование проекта',
          active: true,
        },
      ],
    },
  },
  {
    path: '/projects/show/:id',
    name: 'projects.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр проекта',
      breadcrumb: [
        {
          text: 'Компания',
          active: false,
          to: {
            name: 'company.show',
          },
        },
        {
          text: 'Просмотр проекта',
          active: true,
        },
      ],
    },
  },
]
