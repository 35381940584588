export default [
  {
    path: '/sites/:siteId/portfolios',
    name: 'portfolios',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Портфолио',
      breadcrumb: [
        {
          text: 'Портфолио',
          active: true,
        },
      ],
    },
    props: true,
  },
  {
    path: '/sites/:siteId/portfolios/create',
    name: 'portfolios.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание портфолио',
      breadcrumb: [
        {
          text: 'Портфолио',
          active: false,
          to: {
            name: 'portfolios',
          },
        },
        {
          text: 'Создание портфолио',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/:siteId/portfolios/edit/:id',
    name: 'portfolios.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование портфолио',
      breadcrumb: [
        {
          text: 'Портфолио',
          active: false,
          to: {
            name: 'portfolios',
          },
        },
        {
          text: 'Редактирование портфолио',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/:siteId/portfolios/show/:id',
    name: 'portfolios.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр портфолио',
      breadcrumb: [
        {
          text: 'Портфолио',
          active: false,
          to: {
            name: 'portfolios',
          },
        },
        {
          text: 'Просмотр портфолио',
          active: true,
        },
      ],
    },
  },
]
