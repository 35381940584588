export default [
  {
    path: '/custom-model-items/:entityID',
    name: 'custom-model-items',
    props: true,
    component: () => import('../views/ListView.vue'),
  },
  {
    path: '/custom-model-items/:entityID/create',
    name: 'custom-model-items.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-model-items/edit/:id',
    name: 'custom-model-items.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-model-items/show/:id',
    name: 'custom-model-items.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
    },
  },
]
