export default [
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Сотрудники',
      breadcrumb: [
        {
          text: 'Сотрудники',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/edit/:id',
    name: 'employees.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование сотрудника',
      breadcrumb: [
        {
          text: 'Сотрудники',
          active: false,
          to: {
            name: 'employees',
          },
        },
        {
          text: 'Редактирование сотрудника',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/show/:id',
    name: 'employees.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр сотрудника',
      breadcrumb: [
        {
          text: 'Сотрудники',
          active: false,
          to: {
            name: 'employees',
          },
        },
        {
          text: 'Просмотр сотрудника',
          active: true,
        },
      ],
    },
  },
]
