export default [
  {
    path: '/catalog/:category?',
    name: 'catalog',
    component: () => import('../views/Catalog.vue'),
    props: true,
    meta: {
      auth: true,
      layout: 'vertical-empty',
    },
  },
]
