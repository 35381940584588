export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']('manage_warehouses')) {
    items.push(
      ...[
        {
          title: 'Склады',
          icon: 'PackageIcon',
          route: 'warehouses',
          sort: 103,
          // tag: 'Dev',
          // tagVariant: 'light-secondary',
        },
      ],
    )
  }
  return items
}
