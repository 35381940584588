import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import auth from './auth'
import workingMode from './working-mode'
import menu from './menu'
import basket from './basket'
import invites from './invites'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    menu,
    workingMode,
    basket,
    invites,
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      paths: [
        'auth.token',
        'auth.user',
        'workingMode.mode',
        'basket.items',
      ],
    }),
  ],
})
