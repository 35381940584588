export default [
  {
    path: '/posts',
    name: 'posts',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Статьи',
      breadcrumb: [
        {
          text: 'Статьи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/posts/create',
    name: 'posts.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание статьи',
      breadcrumb: [
        {
          text: 'Статьи',
          active: false,
          to: {
            name: 'posts',
          },
        },
        {
          text: 'Создание статьи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/posts/edit/:id',
    name: 'posts.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование статьи',
      breadcrumb: [
        {
          text: 'Статьи',
          active: false,
          to: {
            name: 'posts',
          },
        },
        {
          text: 'Редактирование статьи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/posts/show/:id',
    name: 'posts.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр статьи',
      breadcrumb: [
        {
          text: 'Статьи',
          active: false,
          to: {
            name: 'posts',
          },
        },
        {
          text: 'Просмотр статьи',
          active: true,
        },
      ],
    },
  },
]
