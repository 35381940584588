export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode']) {
    items.push(
      ...[
        {
          title: 'Отгрузки',
          icon: 'MapPinIcon',
          route: 'shipments',
          sort: 102.5,
          // tag: 'Dev',
          // tagVariant: 'light-secondary',
        },
      ],
    )
  }
  return items
}
