export default [
  {
    path: '/sites/:siteId/custom-objects/create',
    name: 'custom-objects.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-objects/edit/:id',
    name: 'custom-objects.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-models/value/:id',
    name: 'custom-models.value',
    props: true,
    component: () => import('../views/ValueView.vue'),
    meta: {
      auth: true,
    },
  },
]
