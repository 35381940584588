export default [
  {
    path: '/sites/:siteId/custom-models/create',
    name: 'custom-models.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-models/edit/:id',
    name: 'custom-models.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/custom-models/show/:id',
    name: 'custom-models.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр кастомной сущности',
      breadcrumb: [
        {
          text: 'Сайты',
          active: false,
          to: {
            name: 'sites',
          },
        },
        {
          text: 'Просмотр кастомной сущности',
          active: true,
        },
      ],
    },
  },
]
