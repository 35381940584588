export default [
  {
    path: '/sites',
    name: 'sites',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      layout: 'vertical-empty',
    },
  },
]
