export default [
  {
    path: '/global-catalog/:category?',
    name: 'global-catalog',
    component: () => import('../views/GlobalCatalog.vue'),
    props: true,
    meta: {
      auth: true,
      layout: 'vertical-empty',
    },
  },
]
