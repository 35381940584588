export default [
  {
    path: '/domains',
    name: 'domains',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Домены',
      breadcrumb: [
        {
          text: 'Домены',
          active: true,
        },
      ],
    },
  },
  {
    path: '/domains/create',
    name: 'domains.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание домена',
      breadcrumb: [
        {
          text: 'Домены',
          active: false,
          to: {
            name: 'domains',
          },
        },
        {
          text: 'Создание домена',
          active: true,
        },
      ],
    },
  },
  {
    path: '/domains/show/:id',
    name: 'domains.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр домена',
      breadcrumb: [
        {
          text: 'Домены',
          active: false,
          to: {
            name: 'domains',
          },
        },
        {
          text: 'Просмотр домена',
          active: true,
        },
      ],
    },
  },
]
