import axiosIns from '@/libs/axios'

export function getInvite(id) {
  return axiosIns.get(`/business/invites/${id}`)
}

/**
 * Получение списка приглашений
 *
 * Метод возвращает список приглашений отправленных компанией или доступных пользователю, если параметр "company" не указан.
 *
 * @param [company] Идентификатор компании, приглашения которой требуется получить
 * @param [perPage]
 * @param [sortedBy]
 * @param [orderBy]
 * @param [page]
 * @param [search]
 * @param [searchFields]
 * @param [searchJoin]
 * @returns {*}
 */
export function getInvites({
  company, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/invites', {
    params: {
      company,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function createInvite({ phone, company_id, role_id }) {
  return axiosIns.post('/business/invites/', {
    phone,
    company_id,
    role_id,
  })
}

export function acceptInvite(id) {
  return axiosIns.patch(`/business/invites/${id}`)
}

export function deleteInvite(id) {
  return axiosIns.delete(`/business/invites/${id}`)
}

export default {
  getInvite,
  getInvites,
  createInvite,
  acceptInvite,
  deleteInvite,
}
