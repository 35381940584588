export default [
  {
    path: '/sites/store/:siteId/pages/create',
    name: 'sites.store.pages.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/sites/store/pages/edit/:id',
    name: 'sites.store.pages.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/sites/store/pages/content/:id',
    name: 'sites.store.pages.content',
    props: true,
    component: () => import('../views/ContentView.vue'),
    meta: {
      layout: 'vertical-empty',
      auth: true,
    },
  },
]
