import axiosIns from '@/libs/axios'

export function getProjects({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/projects', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getProject(id) {
  return axiosIns.get(`/business/projects/${id}`)
}

export function deleteProject(id) {
  return axiosIns.delete(`/business/projects/${id}`)
}

export function createProject({ name, company_id }) {
  return axiosIns.post('/business/projects', {
    name,
    company_id,
  })
}

export function updateProject({ id, name }) {
  return axiosIns.patch(`/business/projects/${id}`, {
    name,
  })
}

export default {
  getProjects,
  deleteProject,
  createProject,
}
