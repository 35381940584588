export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']('manage_roles')) {
    items.push(
      ...[
        {
          title: 'Роли',
          icon: 'ToolIcon',
          route: 'roles',
          sort: 302,
        },
      ],
    )
  }
  return items
}
