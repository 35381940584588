export default [
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Интеграции',
      breadcrumb: [
        {
          text: 'Интеграции',
          active: true,
        },
      ],
    },
  },
  {
    path: '/integrations/create',
    name: 'integrations.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Подключение интеграции',
      breadcrumb: [
        {
          text: 'Интеграции',
          active: false,
          to: {
            name: 'integrations',
          },
        },
        {
          text: 'Подключение интеграции',
          active: true,
        },
      ],
    },
  },
  {
    path: '/integrations/edit/:type/:id',
    name: 'integrations.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Настройка интеграции',
      breadcrumb: [
        {
          text: 'Интеграции',
          active: false,
          to: {
            name: 'integrations',
          },
        },
        {
          text: 'Настройка интеграции',
          active: true,
        },
      ],
    },
  },
  {
    path: '/integrations/show/:type/:id',
    name: 'integrations.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр интеграции',
      breadcrumb: [
        {
          text: 'Интеграции',
          active: false,
          to: {
            name: 'integrations',
          },
        },
        {
          text: 'Просмотр интеграции',
          active: true,
        },
      ],
    },
  },
]
