export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']('manage_domains')) {
    items.push(
      ...[
        {
          title: 'Домены',
          icon: 'TagIcon',
          route: 'domains',
          sort: 304,
          // tag: 'Dev',
          // tagVariant: 'light-secondary',
        },
      ],
    )
  }
  return items
}
