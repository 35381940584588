export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Заказы',
      breadcrumb: [
        {
          text: 'Заказы',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/orders/show/:id',
  //   name: 'orders.show',
  //   props: true,
  //   component: () => import('../views/ShowView.vue'),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: '/order/:type/:id/',
    name: 'order.show',
    props: true,
    component: () => import('../views/ShowV2View.vue'),
    meta: {
      auth: true,
    },
  },
]
