import pick from 'lodash/pick'
import get from 'lodash/get'
import { getProjects } from '@/services/main-api/business/projects'
import { getMeCompanies } from '@/services/main-api/business/companies'

export default {
  namespaced: true,
  state: {
    companies: [],
    projects: [],
    mode: null,
  },
  getters: {
    companies: state => state.companies,
    projects: state => state.projects,
    mode: state => state.mode,
    options(state, getters) {
      if (!getters.projects.length) {
        return []
      }

      return getters.projects.map(p => {
        const company = getters.companies.find(c => c.id === p.company_id)

        let role = {}

        if (company && company.employee && company.employee.role) {
          role = company.employee.role
        }

        return {
          project: pick(p, ['id', 'name']),
          company: pick(company, ['id', 'name', 'is_trust']),
          role, //
          permissions: get(company, 'employee.permissions', {}),
        }
      })
    },
    selected_company: state => get(state, 'mode.company', null),
    selected_company_id(state) {
      return get(state, 'mode.company.id', null)
    },
    company_id(state, getters) {
      return getters.selected_company_id
    },
    selected_company_name(state) {
      return get(state, 'mode.company.name', null)
    },

    selected_project_id(state) {
      return get(state, 'mode.project.id', null)
    },
    selected_project_name(state) {
      return get(state, 'mode.project.name', null)
    },

    selected_role(state) {
      return get(state, 'mode.role', null)
    },
    permissions(state) {
      return get(state, 'mode.permissions', {})
    },
    checkPermission: (state, getters) => permission => {
      if (Array.isArray(permission)) {
        return permission.some(p => !!getters.permissions[p])
      }
      return !!getters.permissions[permission]
    },
  },
  mutations: {
    SET_COMPANIES(state, value) {
      state.companies = value
    },
    SET_PROJECTS(state, value) {
      state.projects = value
    },
    SET_MODE(state, value) {
      state.mode = value
    },
  },
  actions: {
    async getData({ dispatch }) {
      await Promise.all([
        dispatch('getCompanies'),
        dispatch('getProjects'),
      ])
      await dispatch('checkAndCorrectSelectedMode')
      await dispatch('selectFirstModeIfNoneSelected')
    },
    async getCompanies({ commit }) {
      let companies = []
      try {
        const res = await getMeCompanies({
          perPage: 1000,
        })
        companies = res.data.data
      } catch (e) {
        companies = []
      }
      commit('SET_COMPANIES', companies)
    },
    async getProjects({ commit }) {
      let projects = []
      try {
        const res = await getProjects({
          perPage: 1000,
        })
        projects = res.data.data
      } catch (e) {
        projects = []
      }
      commit('SET_PROJECTS', projects)
    },
    checkAndCorrectSelectedMode({ getters, commit }) {
      if (getters.selected_project_id) {
        if (getters.options.length) {
          const option = getters.options.find(o => o.project.id === getters.selected_project_id)
          if (option) {
            commit('SET_MODE', option)
          } else {
            commit('SET_MODE', null)
          }
        } else {
          commit('SET_MODE', null)
        }
      }
    },
    selectFirstModeIfNoneSelected({ getters, commit }) {
      if (!getters.selected_project_id && getters.options.length) {
        commit('SET_MODE', getters.options[0])
      }
    },
  },
}
