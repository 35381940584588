export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']([
    'manage_orders',
    'manage_catalog_products',
    'manage_catalog_services',
    'manage_product_offers',
  ])) {
    items.push(
      ...[
        {
          header: 'Main',
          sort: 200,
        },
        {
          title: 'Каталог',
          icon: 'FolderIcon',
          route: 'catalog',
          sort: 201,
        },
      ],
    )
  }
  return items
}
