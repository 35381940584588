export default [
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Отгрузки',
      breadcrumb: [
        {
          text: 'Отгрузки',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/warehouses/create',
  //   name: 'warehouses.create',
  //   props: true,
  //   component: () => import('../views/CreateView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Создание склада',
  //     breadcrumb: [
  //       {
  //         text: 'Склады',
  //         active: false,
  //         to: {
  //           name: 'warehouses',
  //         },
  //       },
  //       {
  //         text: 'Создание склада',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouses/edit/:id',
  //   name: 'warehouses.edit',
  //   props: true,
  //   component: () => import('../views/EditView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Редактирование склада',
  //     breadcrumb: [
  //       {
  //         text: 'Склады',
  //         active: false,
  //         to: {
  //           name: 'warehouses',
  //         },
  //       },
  //       {
  //         text: 'Редактирование склада',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouses/show/:id',
  //   name: 'warehouses.show',
  //   props: true,
  //   component: () => import('../views/ShowView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Просмотр склада',
  //     breadcrumb: [
  //       {
  //         text: 'Склады',
  //         active: false,
  //         to: {
  //           name: 'warehouses',
  //         },
  //       },
  //       {
  //         text: 'Просмотр склада',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
