export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode']) {
    if (rootGetters['workingMode/checkPermission']([
      'manage_shipments',
      'manage_orders',
      'manage_warehouses',
    ])) {
      items.push({
        header: 'CRM',
        sort: 101,
      })
    }

    if (rootGetters['workingMode/checkPermission']('manage_orders')) {
      items.push(
        {
          title: 'Заказы',
          icon: 'ShoppingBagIcon',
          route: 'orders',
          sort: 102,
          // tag: 'Dev',
          tagVariant: 'light-secondary',
        },
      )
    }
  }
  return items
}
