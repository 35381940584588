export default [
  // {
  //   path: '/companies',
  //   name: 'companies',
  //   component: () => import('../views/ListView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Компании',
  //     breadcrumb: [
  //       {
  //         text: 'Компании',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/companies/create',
  //   name: 'companies.create',
  //   props: true,
  //   component: () => import('../views/CreateView.vue'),
  //   meta: {
  //     auth: true,
  //     pageTitle: 'Создание компании',
  //     breadcrumb: [
  //       {
  //         text: 'Компании',
  //         active: false,
  //         to: {
  //           name: 'companies',
  //         },
  //       },
  //       {
  //         text: 'Создание компании',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/company',
    name: 'company.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Компания',
      breadcrumb: [
        {
          text: 'Компания',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/edit',
    name: 'company.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование компании',
      breadcrumb: [
        {
          text: 'Компания',
          active: false,
          to: {
            name: 'company.show',
          },
        },
        {
          text: 'Редактирование компании',
          active: true,
        },
      ],
    },
  },
]
