export default {
  namespaced: true,
  state: {
    // Basket
    items: [],

    // AddOfferToBasket
    selectedItemId: {},
    showModalAddOfferToBasket: false,
  },
  getters: {
    // Basket
    items(state, getters, rootState, rootGetters) {
      return state.items.filter(i => i.projectId === rootGetters['workingMode/selected_project_id'])
    },
    count(state, getters) {
      return getters.items.reduce((sum, item) => (sum + item.count), 0)
    },

    // AddOfferToBasket
    selectedItemId: state => state.selectedItemId,
    showModalAddOfferToBasket: state => state.showModalAddOfferToBasket,

  },
  mutations: {

    // AddOfferToBasket
    SET_SELECTED_ITEM_ID(state, value) {
      state.selectedItemId = value
    },
    SET_SHOW_MODAL_ADD_OFFER_TO_BASKET(state, value) {
      state.showModalAddOfferToBasket = value
    },
    ADD_ITEM(state, { projectId, remainId }) {
      const hasItem = state.items.find(i => i.projectId === projectId && i.remainId === remainId)
      if (!hasItem) {
        state.items.push({
          projectId,
          remainId,
          count: 1,
        })
      } else {
        hasItem.count += 1
      }
    },
    REDUCE_ITEM(state, { projectId, remainId }) {
      const hasItem = state.items.find(i => i.projectId === projectId && i.remainId === remainId)
      if (hasItem && hasItem.count > 1) {
        hasItem.count -= 1
      }
    },
    SET_COUNT_ITEM(state, { projectId, remainId, count }) {
      const hasItem = state.items.find(i => i.projectId === projectId && i.remainId === remainId)
      if (hasItem) {
        hasItem.count = count
      }
    },
    DELETE_ITEM(state, { projectId, remainId }) {
      const index = state.items.findIndex(i => i.projectId === projectId && i.remainId === remainId)
      if (index !== -1) {
        state.items.splice(index, 1)
      }
    },
    CLEAR(state, projectId) {
      state.items = state.items.filter(i => i.projectId !== projectId)
    },
  },
  actions: {
    // Basket

    // AddOfferToBasket
    showModal({ commit }, offer) {
      commit('SET_SELECTED_ITEM_ID', offer.id)
      commit('SET_SHOW_MODAL_ADD_OFFER_TO_BASKET', true)
    },
    addItem({ rootGetters, commit }, remain) {
      commit('ADD_ITEM', {
        projectId: rootGetters['workingMode/selected_project_id'],
        remainId: remain.id,
      })
      commit('SET_SHOW_MODAL_ADD_OFFER_TO_BASKET', false)
    },
  },
}
