export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode']) {
    if (
      rootGetters['workingMode/checkPermission']([
        'manage_employees',
        'manage_roles',
        'manage_domains',
        'manage_invites',
      ])
      || rootGetters['invites/count'] > 0
    ) {
      items.push({
        header: 'Администрирование',
        sort: 300,
      })
    }

    if (rootGetters['workingMode/checkPermission']('manage_employees')) {
      items.push(
        {
          title: 'Сотрудники',
          icon: 'UsersIcon',
          route: 'employees',
          sort: 301,
        },
      )
    }
  }
  return items
}
