export default [
  {
    path: '/offers',
    name: 'offers',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Предложения',
      breadcrumb: [
        {
          text: 'Предложения',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/create/:product_id',
    name: 'offers.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание предложения',
      breadcrumb: [
        {
          text: 'Глобальный каталог',
          active: false,
          to: {
            name: 'global-catalog',
          },
        },
        {
          text: 'Создание предложения',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/edit/:id',
    name: 'offers.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование предложения',
      breadcrumb: [
        {
          text: 'Каталог',
          active: false,
          to: {
            name: 'catalog',
          },
        },
        {
          text: 'Редактирование предложения',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offers/show/:id',
    name: 'offers.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр предложения',
      breadcrumb: [
        {
          text: 'Каталог',
          active: false,
          to: {
            name: 'catalog',
          },
        },
        {
          text: 'Просмотр предложения',
          active: true,
        },
      ],
    },
  },
]
