import axiosIns from '@/libs/axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  namespaced: true,
  state: {
    token: '', // хранится в localstorage
    user: {}, // хранится в localstorage
  },
  getters: {
    isLoggedIn: state => !!state.token,
    token: state => state.token,
    user: state => state.user,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, user) {
      state.user = user
    },
    CLEAR_ALL(state) {
      state.token = ''
      state.user = {}
    },
  },
  actions: {
    async login({ commit }, { login, password }) {
      try {
        const res = await axiosIns.post('/auth/login', {
          login,
          password,
        })

        const { token, user } = res.data.data

        axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
        commit('SET_TOKEN', token)
        if (user) {
          commit('SET_USER', user)
        }

        return res
      } catch (error) {
        commit('CLEAR_ALL')
        commit('workingMode/SET_MODE', null, {
          root: true,
        })
        // console.log(error.response)
        throw error
      }
    },
    async findUser({ commit }) {
      try {
        const res = await axiosIns.get('/user/data')
        commit('SET_USER', res.data.data)
        return res
      } catch (error) {
        console.log(error.response.data.message)
        commit('SET_USER', {})
        throw error
      }
    },
    async logout({ commit }) {
      commit('CLEAR_ALL')
      commit('workingMode/SET_MODE', null, {
        root: true,
      })
      commit('menu/RESET_MENU', null, {
        root: true,
      })
      delete axiosIns.defaults.headers.common.Authorization
      await router.push({ name: 'login' })
    },
    refreshToken({ dispatch }, token) {
      return axiosIns.post('/auth/refresh', {
        token,
      }).catch(async error => {
        await dispatch('logout')
        throw error
      })
    },
  },
}
