import axiosIns from '@/libs/axios'

export function getCompanies({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/companies', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getMeCompanies({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/companies/me', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getCompany(id) {
  return axiosIns.get(`/business/companies/${id}`)
}

export function deleteCompany(id) {
  return axiosIns.delete(`/business/companies/${id}`)
}

export function createCompany({ name }) {
  return axiosIns.post('/business/companies', {
    name,
  })
}

export function updateCompany({ id, name }) {
  return axiosIns.patch(`/business/companies/${id}`, {
    name,
  })
}

export default {
  getCompanies,
  deleteCompany,
  createCompany,
  getMeCompanies,
}
