// todo Перенести потом в модуль
import { getInvites } from '@/services/main-api/business/invites'

export default {
  namespaced: true,
  state: {
    count: 0,
  },
  getters: {
    count: state => state.count,
  },
  mutations: {
    SET_COUNT(state, value) {
      state.count = value
    },
  },
  actions: {
    async getCount({ commit }) {
      try {
        const res = await getInvites({
          perPage: 1000,
          page: 1,
        })

        if (Array.isArray(res.data.data)) {
          commit('SET_COUNT', res.data.data.length)
        } else {
          commit('SET_COUNT', 0)
        }
      } catch (e) {
        commit('SET_COUNT', 0)
      }
    },
  },
}
