export default [
  {
    path: '/invites',
    name: 'invites',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Приглашения',
      breadcrumb: [
        {
          text: 'Приглашения',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invites/create',
    name: 'invites.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Пригласить сотрудника',
      breadcrumb: [
        {
          text: 'Приглашения',
          active: false,
          to: {
            name: 'invites',
          },
        },
        {
          text: 'Пригласить сотрудника',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-invites',
    name: 'invites.my',
    component: () => import('../views/MyInvitesView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Мои приглашения',
      breadcrumb: [
        {
          text: 'Мои приглашения',
          active: true,
        },
      ],
    },
  },
]
