export default []

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']('manage_invites')) {
    items.push({
      title: 'Приглашения',
      icon: 'UserPlusIcon',
      route: 'invites',
      sort: 303,
      disabled: !rootGetters['workingMode/checkPermission']('manage_invites'),
    })
  }

  if (rootGetters['invites/count'] > 0) {
    items.push({
      title: 'Мои приглашения',
      icon: 'UserCheckIcon',
      sort: 2,
      tag: rootGetters['invites/count'],
      tagVariant: 'primary',
      route: 'invites.my',
    })
  }

  return items
}
