export default [
]

export function dynamicItemsGetter(rootState, rootGetters) {
  const items = []
  if (rootGetters['workingMode/mode'] && rootGetters['workingMode/checkPermission']('manage_sites')) {
    items.push(
      ...[
        {
          header: 'Сайты',
          sort: 280,
        },
        {
          title: 'Сайты ',
          icon: 'ChromeIcon',
          route: 'sites',
          sort: 281,
          tag: 'Dev',
          tagVariant: 'light-secondary',
          disabled: !rootGetters['workingMode/checkPermission']('manage_sites'),
        },
      ],
    )
  }
  return items
}
