let staticItems = [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    sort: 0,
  },
]
const dynamicItemsGetters = []

const context = require.context('@/modules', true, /navigation\/index\.js$/)
context.keys().forEach(path => {
  // Заполняем статичные пункты меню из модулей
  if (Array.isArray(context(path).default)) {
    staticItems = staticItems.concat(context(path).default)
  }
  // Записываем динамические пункты меню из модулей
  if (typeof context(path).dynamicItemsGetter === 'function') {
    dynamicItemsGetters.push(context(path).dynamicItemsGetter)
  }
})

export default {
  namespaced: true,
  state: {
    verticalMenuItems: [
      ...staticItems,
    ],
  },
  getters: {
    verticalMenuItems(state, getters, rootState, rootGetters) {
      const items = [...state.verticalMenuItems]

      dynamicItemsGetters.forEach(dIGetter => {
        const dItems = dIGetter(rootState, rootGetters)
        if (Array.isArray(dItems) && dItems.length) {
          items.push(...dItems)
        }
      })

      return items.sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
  },
  mutations: {
    SET_VERTICAL_MENU_ITEMS(state, items) {
      state.verticalMenuItems = items
    },
    RESET_MENU(state) {
      state.verticalMenuItems = [...staticItems]
    },
    ADD_VERTICAL_MENU_ITEM(state, item) {
      state.verticalMenuItems.push(item)
    },
    ADD_VERTICAL_MENU_ITEMS(state, items) {
      state.verticalMenuItems.push(...items)
    },
  },
  actions: {},
}
