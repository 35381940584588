export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/ListView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Роли',
      breadcrumb: [
        {
          text: 'Роли',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/create',
    name: 'roles.create',
    props: true,
    component: () => import('../views/CreateView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Создание роли',
      breadcrumb: [
        {
          text: 'Роли',
          active: false,
          to: {
            name: 'roles',
          },
        },
        {
          text: 'Создание роли',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'roles.edit',
    props: true,
    component: () => import('../views/EditView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Редактирование роли',
      breadcrumb: [
        {
          text: 'Роли',
          active: false,
          to: {
            name: 'roles',
          },
        },
        {
          text: 'Редактирование роли',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/show/:id',
    name: 'roles.show',
    props: true,
    component: () => import('../views/ShowView.vue'),
    meta: {
      auth: true,
      pageTitle: 'Просмотр роли',
      breadcrumb: [
        {
          text: 'Роли',
          active: false,
          to: {
            name: 'roles',
          },
        },
        {
          text: 'Просмотр роли',
          active: true,
        },
      ],
    },
  },
]
