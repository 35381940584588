import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

Vue.use(VueRouter)

let moduleRoutes = []

const context = require.context('@/modules', true, /router\/index\.js$/)
context.keys().forEach(path => {
  moduleRoutes = moduleRoutes.concat(context(path).default)
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        auth: true,
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     breadcrumb: [
    //       {
    //         text: 'Second Page',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/LoginView.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/RegisterView.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPasswordView.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    ...moduleRoutes,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// Проверка защищенных маршрутов
router.beforeEach((to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to.meta, 'auth')) {
    const isLoggedIn = store.getters['auth/isLoggedIn']
    const metaAuth = to.meta.auth
    if (!metaAuth && isLoggedIn) {
      if (from.name) {
        next({ name: from.name })
        return
      }
      next({ name: 'dashboard' })
      return
    }
    if (metaAuth && !isLoggedIn) {
      next({ name: 'login' })
      return
    }
  }
  next()
})

export default router
