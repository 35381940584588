import store from '@/store'

export default {
}

export async function start() {
  if (store.getters['auth/isLoggedIn']) {
    store.dispatch('invites/getCount')
  }
}

export async function afterLogin() {
  store.dispatch('invites/getCount')
}
